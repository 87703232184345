import React from "react";
import { TbGridDots } from "react-icons/tb";
import chromeIcon from "../../assets/icons/icons8-chrome.svg";
import folderIcon from "../../assets/icons/icons8-folder.svg";
import ideIcon from "../../assets/icons/icons8-intellij-idea.svg";
import musicAppIcon from "../../assets/icons/icons8-music.svg";
import terminalIcon from "../../assets/icons/icons8-linux-terminal.svg";
import githubIcon from "../../assets/icons/icons8-github.svg";
import linkedInIcon from "../../assets/icons/icons8-linkedin.svg";
import gnomeReaderIcon from "../../assets/icons/1024px-GNOME_Document_Viewer_icon_2019.svg.svg";
import { connect } from "react-redux";
import { ubuntuActions } from "../../redux/actions";

const SideBar = ({ openApp, idGen, appsList, lang, menu, setMenu }) => {
  let items = [
    {
      title: "Google Chrome",
      isOpened: appsList.find((itm) => itm.relatedApp == "Google Chrome"),
      icon: chromeIcon,
      onClick: () => {
        if (!appsList.find((itm) => itm.relatedApp == "Google Chrome"))
          openApp({
            id: idGen,
            relatedApp: "Google Chrome",
            data: null,
          });
      },
    },
    {
      title: "Files",
      isOpened: false,
      icon: folderIcon,
      onClick: () => {
        openApp({
          id: idGen,
          relatedApp: "Alert",
          data: {
            title: lang == "English" ? "Coming Soon!" : "به زودی!",
            message:
              lang == "English"
                ? "There is No Files App In This Version..."
                : "هنوز در این ورژن از سایت اپلیکیشن فایل وجود ندارد",
          },
        });
      },
    },
    {
      title: "I Don't Use This One",
      isOpened: false,
      icon: ideIcon,
      onClick: () => {
        openApp({
          id: idGen,
          relatedApp: "Alert",
          data: {
            title: lang == "English" ? "Coming Soon!" : "به زودی!",
            message:
              lang == "English"
                ? "There is No IDE App In This Version..."
                : "هنوز در این ورژن از سایت اپلیکیشن کد ادیتور وجود ندارد",
          },
        });
      },
    },
    {
      title: "Music App",
      isOpened: false,
      icon: musicAppIcon,
      onClick: () => {
        openApp({
          id: idGen,
          relatedApp: "Alert",
          data: {
            title: lang == "English" ? "Coming Soon!" : "به زودی!",
            message:
              lang == "English"
                ? "There is No Music App In This Version..."
                : "هنوز در این ورژن از سایت اپلیکیشن موزیک وجود ندارد",
          },
        });
      },
    },
    {
      title: "Terminal",
      isOpened: false,
      icon: terminalIcon,
      onClick: () => {
        openApp({
          id: idGen,
          relatedApp: "Alert",
          data: {
            title: lang == "English" ? "Coming Soon!" : "به زودی!",
            message:
              lang == "English"
                ? "There is No Terminal In This Version..."
                : "هنوز در این ورژن از سایت ترمینال وجود ندارد",
          },
        });
      },
    },
    {
      title: "My LinkedIn",
      isOpened: false,
      icon: linkedInIcon,
      onClick: () => {
        window.open("https://www.linkedin.com/in/pedram-keshavarzi-dev/");
      },
    },
    {
      title: "My Github",
      isOpened: appsList.find((itm) => itm.relatedApp == "Github"),
      icon: githubIcon,
      onClick: () => {
        window.open("https://github.com/pkpedram");
      },
    },
    {
      title: "My resume",
      isOpened: appsList.find((itm) => itm.relatedApp == "Doc Reader"),
      icon: gnomeReaderIcon,
      onClick: () => {
        if (!appsList.find((itm) => itm.relatedApp == "Doc Reader"))
          openApp({
            id: idGen,
            relatedApp: "Doc Reader",
            data: null,
          });
      },
    },
  ];

  return (
    <div className="w-14 h-full bg-ubuntuDarkPurple/80 flex flex-col items-center justify-between py-3 px-1">
      <div className="flex items-center flex-col">
        {items?.map((item, idx) => (
          <div
            className="w-full aspect-square hover:bg-ubuntuGray/70 p-1 rounded-lg relative flex items-center sideBarItem"
            onClick={item.onClick}
          >
            {item.isOpened && (
              <span className="mr-1 absolute w-2 h-2 rounded-full bg-ubuntuOrange"></span>
            )}
            <p className="text-xs absolute min-w-max text-white bg-ubuntuGray p-1 rounded-full left-14 px-2">
              {item.title}
            </p>
            <img src={item.icon} />
          </div>
        ))}
      </div>
      <div
        className="text-3xl text-white hover: hover:bg-ubuntuGray/70 p-1 rounded-lg cursor-pointer"
        onClick={() => setMenu(!menu)}
      >
        <TbGridDots />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  idGen: state.ubuntuState.appsList.length + 1,
  appsList: state.ubuntuState.appsList,
  lang: state.publicApi.lang,
  menu: state.ubuntuState.menu,
});
const mapDispatchToProps = {
  openApp: ubuntuActions.openApp,
  setMenu: ubuntuActions.setMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
