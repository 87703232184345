import ronix from "../assets/img/ronix.webp";
import roomDemo from "../assets/img/Frame.svg";

export const portfolioData = [
  {
    id: 1,
    title: "Ronix 3d Landing Page",
    faTitle: "لندینگ سه بعدی رونیکس",
    link: "https://ronix.pedramksh.ir",
    image: ronix,
  },
  {
    id: 2,
    title: "3D Room Configurator",
    faTitle: "کانفیگوریتور اتاق",
    link: "https://room.pedramksh.ir",
    image: roomDemo,
  },
];
